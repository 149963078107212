<template>
  <v-btn @click="refreshClients">
    Refresh clients
  </v-btn>
</template>

<script>
import api from '@/helpers/api'

export default {
  name: 'Refresh',
  methods: {
    refreshClients: api.bind(null, '/refresh/clients')
  }
}
</script>
